<template>
  <div class="publishImg">
    <div class="navBar">
      <div @click="backPage">
        <svg-icon class="navArrow" iconClass="navArrow" />
      </div>
      <div class="navTitle">发布图文</div>
      <div class="rightBtn" @click="$router.push('/uploadRules')">发布规则</div>
      <!--            <div class="rightBtn" @click="publish">发布</div>-->
    </div>
    <div class="main">
      <!-- 选择话题 -->
      <div
        class="topicBox"
        v-if="!(tags.length > 0)"
        @click="$router.push('/selectTag')"
      >
        <div class="topicLeft">
          <svg-icon icon-class="#"></svg-icon>
          <div>选择话题</div>
        </div>
        <div class="topicRight">
          <div>请遵守社区规则</div>
          <svg-icon icon-class="rightGray"></svg-icon>
        </div>
      </div>
      <div class="topicBox" v-else @click="$router.push('/selectTag')">
        <div class="topicLeft">
          <ImgDecypt
            class="avatarImg"
            v-if="showTag"
            :src="tags[0].coverImg"
            :key="tags[0].coverImg"
          />
          <div class="tagInfo">
            <div class="name">{{ tags[0].name }}</div>
            <div class="desc">{{ tags[0].tagDesc }}</div>
          </div>
        </div>
        <div class="topicRight">
          <svg-icon icon-class="rightGray"></svg-icon>
        </div>
      </div>

      <van-field
        class="newInput"
        v-model="title"
        autosize
        placeholder="请填写标题"
      />
      <van-field
        class="newInput"
        v-model="content"
        rows="3"
        autosize
        type="textarea"
        maxlength="150"
        placeholder="详细描述下吧～留下你的文采..."
        show-word-limit
      />
      <div class="publishTitle">添加图集</div>
      <div class="uploaderBox" @click.stop>
        <UploaderFile
          @uploadSuccess="uploadSuccess"
          @delImg="delImg"
          ref="UploadFile"
          :multiple="true"
        >
        </UploaderFile>
      </div>
      <!-- 关联视频 -->
      <!--            <div class="correlationVideo">-->
      <!--                <div class="topicLeft">-->
      <!--                    <svg-icon icon-class="correlationIcon"></svg-icon>-->
      <!--                    <div>关联视频</div>-->
      <!--                </div>-->
      <!--                <div class="topicRight">-->
      <!--                    <div>喜欢中选择</div>-->
      <!--                    <svg-icon icon-class="rightGray"></svg-icon>-->
      <!--                </div>-->
      <!--            </div>-->

      <!-- 设置价格 -->
      <div class="settingPrice" @click="setPrice">
        <div class="topicLeft">
<!--          <svg-icon icon-class="gold2Icon"></svg-icon>-->
          <div class="gold2Icon"></div>
          <div>设置价格</div>
        </div>
        <div class="topicRight">
          <div :class="price > 0 ? 'price' : ''">
            {{ price > 0 ? price + "金币" : "免费" }}
          </div>
          <svg-icon icon-class="rightGray"></svg-icon>
        </div>
      </div>

      <!-- 发布 -->
      <div class="btnGroup" @click="publish">
        <div class="btn">确认发布</div>
      </div>
      <!--            <div class="publishTitle mt18">选择话题</div>-->
      <!--            <div class="tagList">-->
      <!--                <div class="tagItem" v-for="(item) in tags" :key="item.id">-->
      <!--                    <div class="tagCloseBtn" @click="delTag(item.name)">-->
      <!--                        <svg-icon class="tagClose" iconClass="tagClose" />-->
      <!--                    </div>-->
      <!--                    #{{ item.name }}-->
      <!--                </div>-->
      <!--                <router-link tag="div" to="/selectTag" class="addTag">-->
      <!--                    <svg-icon class="addTagIcon" iconClass="addTag" />-->
      <!--                </router-link>-->
      <!--            </div>-->
      <!--            <div class="publishTitle">设置价格</div>-->
      <!--            <div class="priceBox">-->
      <!--                <svg-icon class="priceIcon" iconClass="gold" />-->
      <!--                <div class="price" @click="setPrice">{{price}}</div>-->
      <!--            </div>-->
    </div>
    <PostAmountPop ref="postAmountPop" @setAmount="setAmount" />
  </div>
</template>

<script>
import { Field, Toast } from "vant";
import { mapGetters } from "vuex";
import UploaderFile from "@/components/UploaderFile";
import PostAmountPop from "@/components/PostAmountPop";
import { releaseVidSubmit } from "@/api/community";
import ImgDecypt from "@/components/ImgDecypt";
export default {
  name: "publishImg",
  components: {
    UploaderFile,
    PostAmountPop,
    [Field.name]: Field,
    ImgDecypt,
  },
  computed: {
    ...mapGetters({
      tags: "tags",
    }),
  },
  watch: {
    $route(to, from) {
      if (from.path === "/selectTag" && this.tags.length) {
        this.showTag = true;
      } else {
        this.showTag = false;
      }
    },
  },
  data() {
    return {
      placeholderText: "请输入标题（至少三个字）～ \n最多字是255个字",
      title: "",
      content: "",
      imgList: [],
      price: 0,
      showTag: false,
    };
  },
  methods: {
    // 返回
    backPage() {
      this.$store.commit("commnity/CLEARALL_TAGS");
      this.$router.go(-1);
    },
    uploadSuccess(img) {
      this.imgList.push(img);
    },
    delImg(index) {
      this.imgList.splice(index, 1);
    },
    //删除标签
    delTag(name) {
      this.$store.commit("commnity/DELITEM_TAG", name);
    },
    async publish() {
      if (this.tags.length <= 0) {
        Toast("请选择话题");
        return;
      }
      if (!this.title) {
        Toast("请输入标题");
        return;
      }
      if (!this.content) {
        Toast("请输入内容");
        return;
      }
      if (this.imgList.length <= 0) {
        Toast("请上传图片");
        return;
      }

      let req = {
        title: this.title,
        content: this.content,
        newsType: "COVER",
        tags: this.tags.map((e) => e.id),
        seriesCover: this.imgList,
        coins: parseInt(this.price),
      };
      try {
        this.$store.commit("app/SET_LOADING", true);
        let res = await this.$Api(releaseVidSubmit, req);
        this.$store.commit("app/SET_LOADING", false);
        if (res.code == 200) {
          this.imgList = [];
          this.content = "";
          this.price = 0;
          this.$store.commit("commnity/CLEARALL_TAGS");
          this.$router.push("/creationCenter");
          Toast("发布成功");
          return;
        }
        Toast(res.tip || "发布失败");
      } catch (error) {
        this.$store.commit("app/SET_LOADING", false);
        Toast("发布失败");
      }
    },
    setPrice() {
      this.$refs.postAmountPop.showPop();
    },
    setAmount(amount) {
      this.price = amount;
    },
  },
};
</script>

<style lang="scss" scoped>
.publishImg {
  height: 100%;
  color: #000;

  .navBar {
    height: 44px;
    padding: 0 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    //border-bottom: 1px solid #e6e6e6;

    .navArrow {
      width: 17px;
      height: 17px;
    }

    .navTitle {
      flex: 2;
      text-align: center;
      font-size: 16px;
      color: #333333;
      padding-left: 35px;
    }

    .rightBtn {
      font-size: 14px;
      color: #666666;
      // width: 17px;
      // height: 17px;
    }
  }
  .main {
    height: calc(100% - 45px);
    overflow-y: auto;
    //background: #f5f5f5;
    padding: 18px 16px;

    .topicBox {
      height: 50px;
      border-radius: 8px;
      background: #F9F9F9;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      color: #666666;
      padding: 0 12px;

      .topicLeft {
        display: flex;
        //align-items: center;

        .avatarImg {
          width: 38px;
          height: 38px;
          border-radius: 4px;

          /deep/ .van-image__img {
            border-radius: 4px;
          }
        }

        .tagInfo {
          margin-left: 8px;

          .name {
            color: #333;
            font-size: 14px;
            font-weight: 500;
          }

          .desc {
            color: #666;
            font-size: 12px;
            font-weight: 400;
          }
        }

        svg {
          width: 16px;
          height: 16px;
          margin-right: 6px;
        }
      }

      .topicRight {
        display: flex;
        align-items: center;

        svg {
          width: 12px;
          height: 12px;
          margin-left: 10px;
        }
      }
    }

    .correlationVideo {
      height: 50px;
      border-radius: 8px;
      background: #ebebeb;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      color: #666666;
      padding: 0 12px;
      margin-top: 18px;

      .topicLeft {
        display: flex;
        //align-items: center;

        svg {
          width: 16px;
          height: 16px;
          margin-right: 6px;
        }
      }

      .topicRight {
        display: flex;
        align-items: center;
        color: #999;

        svg {
          width: 12px;
          height: 12px;
          margin-left: 10px;
        }
      }
    }

    .settingPrice {
      height: 50px;
      border-radius: 8px;
      background: #FAFAFA;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      color: #666666;
      padding: 0 12px 0 8px;
      margin-top: 18px;

      .topicLeft {
        display: flex;
        align-items: center;

        .gold2Icon{
          width: 24px;
          height: 24px;
          background-image: url("../../../assets/png/gold2Icon.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          margin-right: 4px;
        }

        svg {
          width: 24px;
          height: 24px;
          margin-right: 4px;
          margin-top: 5px;
        }
      }

      .topicRight {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 14px;

        svg {
          width: 12px;
          height: 12px;
          margin-left: 10px;
        }

        .price {
          color: #FBD13D;
        }
      }
    }

    .btnGroup {
      display: flex;
      justify-content: center;
      margin: 91px 0;

      .btn {
        width: 164px;
        height: 46px;
        border-radius: 22px;
        background: #FBD13D;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #424242;
      }
    }
  }

  .newInput {
    margin-top: 11px;
    // padding: 0 0 12px 0;
    box-sizing: border-box;
    margin-bottom: 15px;
    background: #F9F9F9;
    // border-bottom: 1px solid #282544;
    border-radius: 5px;

    ::placeholder {
      font-size: 12px;
      color: #999999;
    }

    /deep/ .van-field__control {
      font-size: 12px;
      color: #000;
    }

    /deep/ .van-field__word-limit {
      font-size: 12px;
      color: #666;
    }

    .clearBtn {
      margin-right: 12px;
      width: 22px;
      height: 22px;

      .inputClose {
        width: 100%;
        height: 100%;
      }
    }
  }

  .publishTitle {
    font-size: 16px;
    font-weight: 500;
    color: #333;
    //padding: 0 12px;
    box-sizing: border-box;
  }

  .priceBox {
    padding: 0 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    .priceIcon {
      margin: 12px 9px 0 0;
      width: 43px;
      height: 35px;
    }
    .price {
      width: 55px;
      height: 21px;
      border-radius: 6px;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #e6e6e6;
    }
  }

  .uploaderBox {
    margin-top: 12px;
    //padding: 0 12px;
    box-sizing: border-box;
  }
  .tagList {
    display: flex;
    font-size: 12px;
    color: #000;
    margin-top: 11px;
    flex-wrap: wrap;
    padding: 0 12px;
    box-sizing: border-box;
    .tagItem {
      position: relative;
      margin-right: 18px;
      margin-bottom: 18px;
      padding: 2px 6px;
      border-radius: 6px;
      background: #e6e6e6;
      .tagCloseBtn {
        position: absolute;
        top: -7px;
        right: -7px;
        .tagClose {
          width: 12px;
          height: 12px;
        }
      }
    }
    .addTag {
      margin-bottom: 18px;
      .addTagIcon {
        width: 21px;
        height: 21px;
      }
    }
  }
  .mt18 {
    margin-top: 18px;
  }
}
</style>
